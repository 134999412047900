<template>
  <div class="customerServiceConfig">
    <el-scrollbar class="elScroll">
      <div class="search_sList">
        <div class="top_opera">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            label-width="100px"
          >
            <el-form-item label="管理省份">
              <el-select
                v-model="formInline.provinceId"
                placeholder="管理省份"
                filterable
                clearable
              >
                <el-option
                  v-for="item in provinceData"
                  :key="item.provinceId"
                  :label="item.provinceName"
                  :value="item.provinceId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客服形式">
              <el-select
                v-model="formInline.type"
                placeholder="客服形式"
                filterable
                clearable
              >
                <el-option
                  v-for="(item, key) in $common.cusTypeData"
                  :key="key"
                  :label="item"
                  :value="key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="客服组名称">
              <el-input
                v-model="formInline.name"
                placeholder="客服组名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="客服成员">
              <el-select
                v-model="formInline.userId"
                placeholder="客服成员"
                filterable
                clearable
              >
                <el-option
                  v-for="item in customerServiceData"
                  :key="item.userId"
                  :label="item.realName"
                  :value="item.userId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="管理学校">
              <el-select
                v-model="formInline.tenantId"
                placeholder="管理学校"
                filterable
                clearable
              >
                <el-option
                  v-for="item in provinceSchoolData"
                  :key="item.tenantId"
                  :label="item.tenantName"
                  :value="item.tenantId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="省份默认客服">
              <el-select
                v-model="formInline.provinceFlag"
                placeholder="省份默认客服"
                filterable
              >
                <el-option
                  v-for="(item, key) in $common.provinceFlagData"
                  :key="key"
                  :label="item"
                  :value="key"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="btn_area">
            <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
            <el-button type="info" plain @click="resetEvent">重置</el-button>
          </div>
        </div>
      </div>
      <div class="table_sList">
        <div class="opera_btnArea">
          <el-button
            v-if="$isShowModle('customer_config_add')"
            class="mainBtn"
            @click="manageCusEvent('add')"
            plain
            >添加客服</el-button
          >
          <el-button
            v-if="$isShowModle('customer_config_set_general')"
            class="mainBtn"
            @click="cusSetEvent"
            plain
            >设置通用客服</el-button
          >
        </div>
        <el-table
          :data="tableData"
          stripe
          border
          style="width: 100%"
          align="center"
        >
          <el-table-column
            v-for="(item, index) in columnList"
            :key="index"
            :prop="item.prop"
            :label="item.label ? item.label : '/'"
            :width="item.width"
            align="center"
          >
            <template slot-scope="scope">
              <template v-if="scope.column.property === 'name' || scope.column.property === 'enterpriseWechatUrl' || scope.column.property === 'userNames'">
                <el-tooltip
                  :content="scope.row[scope.column.property]"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                  v-if="scope.row[scope.column.property] != 'total'"
                >
                  <div class="ellipsis" v-if="!toolItem.includes(scope.column.property)">{{ scope.row[scope.column.property] || '/' }}</div>
                  <div class="ellipsis blue-btn" v-else @click="toolEvent(scope.row, scope.column.property)">{{ scope.row[scope.column.property] || '/' }}</div>
                </el-tooltip>
              </template>
              <template v-else-if="scope.column.property === 'type'">
                <span>{{ scope.row.type ? $common.cusTypeData[scope.row.type] : '/' }}</span>
              </template>
              <template v-else-if="scope.column.property === 'provinceFlag' && $isShowModle('customer_config_del')">
                <el-switch
                  v-model="scope.row.provinceFlag"
                  :active-value="1"
                  :inactive-value="0"
                  @change="chageProStatus(scope.row)">
                </el-switch>
              </template>
              <template v-else-if="scope.column.property === 'commercialSchoolCount' || scope.column.property === 'showSchoolCount'">
                <el-button @click="watchSch(scope.row, scope.column.property)" type="text" size="small">{{ scope.row[scope.column.property] || 0 }}</el-button>
              </template>
              <span v-else-if="scope.row[scope.column.property] == null">/</span>
              <span v-else>
                {{ scope.row[scope.column.property] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            align="center"
            width="120"
          >
            <template slot-scope="scope">
              <el-button v-if="$isShowModle('customer_config_edit')" @click="manageCusEvent('edit', scope.row)" type="text" size="small">编辑</el-button>
              <el-button v-if="$isShowModle('customer_config_del')" @click="deleteCus(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
      </div>
    </el-scrollbar>

    <!-- 客服成员 -->
    <dialogDiyNew :isShow="isShowCus" :dialogDiyData="dialogCusData"
    @dialogEvent="dialogCusEvent">
      <div slot="dialogContent" class="cus_area">
        <el-table
          :data="cusList"
          stripe
          border
          style="width: 100%"
          align="center"
          height="460"
        >
          <el-table-column
            prop="realName"
            label="成员姓名"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="userName"
            label="成员账号"
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>
    </dialogDiyNew>

    <!-- 添加、编辑客服 -->
    <dialogDiyNew :isShow="isShowMan" :dialogDiyData="dialogManData"
    @dialogEvent="dialogManEvent">
      <div slot="dialogContent" class="man_area">
        <manageCusService @getTableData="getTableData" @dialogManEvent="dialogManEvent" ref="mcs"></manageCusService>
      </div>
    </dialogDiyNew>

    <!-- 设置通用客服 -->
    <dialogDiyNew :isShow="isShowUniversal" :dialogDiyData="dialogUniData"
    @dialogEvent="dialogUniEvent">
      <div slot="dialogContent" class="uni-area">
        <p><span class="red-pointed">*</span>通用客服企业微信链接</p>
        <el-input
          v-model="url"
          placeholder="通用客服企业微信链接"
          clearable
        ></el-input>
        <p><span class="red-tiped">注：</span>未配置客服的省份学校将默认为该客服链接。</p>
        <div class="btn_area">
          <el-button type="primary" @click="setUniCus">确定</el-button>
          <el-button @click="dialogUniEvent">取消</el-button>
        </div>
      </div>
    </dialogDiyNew>

    <!-- 学校列表 -->
    <dialogDiyNew :isShow="isShowSch" :dialogDiyData="dialogSchData"
    @dialogEvent="dialogSchEvent">
      <div slot="dialogContent" class="sch_area">
        <simpleSchoolList @dialogSchEvent="dialogSchEvent" ref="simpleSch"></simpleSchoolList>
      </div>
    </dialogDiyNew>

    <handle
      :isShow="isShowDel"
      :handleText="handleText"
      @handlesEvent="doHandleEvent"
    >
      <div class="btn_area" slot="handle_btn">
        <el-button @click="doHandleEvent">关闭</el-button>
        <el-button type="primary" @click="deleStu">确定</el-button>
      </div>
    </handle>
  </div>
</template>

<script>
import publicMethod from '@/components/utils/publicMethod'
const Pagination = () => import('@/components/Pagination')
const dialogDiyNew = () => import('@/components/commonModule/dialogDiyNew')
const manageCusService = () => import('./manageCusService')
const simpleSchoolList = () => import('./simpleSchoolList')
const handle = () => import('@/components/commonModule/handle')
export default {
  name: 'customerServiceConfig',
  components: {
    Pagination,
    dialogDiyNew,
    handle,
    manageCusService,
    simpleSchoolList,
  },
  data () {
    return {
      formInline: {
        name: null,
        page: 1,
        pageSize: 10,
        provinceFlag: null,
        provinceId: null,
        tenantId: null,
        type: null,
        userId: null
      },
      provinceData: [],
      allSchoolData: [],
      tableData: [],
      columnList: [
        {
          prop: "provinceName",
          label: "管理省份",
          width: 160
        },
        {
          prop: "type",
          label: "客服形式",
          width: 100
        },
        {
          prop: "name",
          label: "客服组名称"
        },
        {
          prop: "userNames",
          label: "客服成员",
          width: 160
        },
        {
          prop: "commercialSchoolCount",
          label: "商用学校数",
          width: 100
        },
        {
          prop: "commercialSchoolPeopleNum",
          label: "商用学校总人数",
          width: 120
        },
        {
          prop: "showSchoolCount",
          label: "演示学校数",
          width: 100
        },
        {
          prop: "showSchoolPeopleNum",
          label: "演示学校总人数",
          width: 120
        },
        {
          prop: "provinceFlag",
          label: "省份默认客服",
          width: 100
        },
        {
          prop: "enterpriseWechatUrl",
          label: "企业微信客服链接"
        }
      ],
      childMsg: {
        current: 1,
        size: 10,
        total: 0,
      },
      isShowCus: false,
      dialogCusData: {
        title: "客服成员",
        sizeStyle: { width: "600px", height: "600px" }
      },
      cusList: [],
      isShowMan: false,
      dialogManData: {
        title: null,
        sizeStyle: { width: "500px", height: "480px" }
      },
      isShowDel: false,
      handleText: null,
      isShowUniversal: false,
      dialogUniData: {
        title: '设置通用客服',
        sizeStyle: { width: "500px", height: "320px" }
      },
      isShowSch: false,
      dialogSchData: {
        title: null,
        sizeStyle: { width: "900px", height: "820px" }
      },
      rowData: {},
      url: null,
      provinceSchoolData: [],
      customerServiceData: [],
      toolItem: ['enterpriseWechatUrl', 'userNames']
    }
  },
  mounted() {
    this.getProviceEvent()
    this.getProvinceToSchoolList()
    this.getCustomerServiceObjList()
    this.getTableData()
  },
  watch: {
    'formInline.provinceId'(newVal, oldVal) {
      this.getProvinceToSchoolList()
    }
  },
  methods: {
    ...publicMethod,
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getTableData();
    },
    // 重置
    resetEvent() {
      Object.assign(this.$data.formInline, this.$options.data().formInline)
    },
    // 获取表格数据
    getTableData() {
      this.$request.customerServiceList(this.formInline,
        ).then(res => {
        if (res.data.code == 0) {
          let { records, current, size, total } = res.data.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getTableData();
    },
    // 添加客服
    manageCusEvent(type, row) {
      this.dialogManData.title = type == 'add' ? '添加客服' : '编辑客服'
      this.dialogManEvent()
      let obj = {
        provinceData: this.provinceData,
        row,
        type
      }
      this.$nextTick(() => {
        this.$refs.mcs.sendData(obj)
      })
    },
    // 设置通用客服
    cusSetEvent() {
      this.$request.getCompanyWebsite({ type: 'enterprise_wechat_link', labelName: 'platformGeneralCustomerEnterpriseWeChat' }).then((res) => {
        this.url = res.data
        this.dialogUniEvent()
      })
    },
    dialogUniEvent() {
      this.isShowUniversal = !this.isShowUniversal
    },
    setUniCus() {
      this.$request.addCompanyWebsite({ url: this.url }).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
          this.dialogUniEvent()
          this.getTableData()
        }
      })
    },
    // 查看学校列表
    watchSch(row, flag) {
      this.dialogSchData.title = flag == 'commercialSchoolCount' ? '商用学校列表' : '演示学校列表'
      let obj = {
        customerConfig: row.id,
        provinceId: row.provinceId,
        nature: flag == 'commercialSchoolCount' ? 1 : 2
      }
      this.dialogSchEvent()
      this.$nextTick(() => {
        this.$refs.simpleSch.sendData(obj)
      })
    },
    dialogSchEvent() {
      this.isShowSch = !this.isShowSch
      if (!this.isShowSch) {
        this.getTableData()
        this.getProvinceToSchoolList()
      }
    },
    watchCus(row) {
      this.$request.customerServiceObjList({userIds: row.userIdsStr.split(',')}).then(res => {
        if (res.data.code == 0) {
          this.cusList = res.data.data
          this.dialogCusEvent()
        }
      })
    },
    dialogCusEvent() {
      this.isShowCus = !this.isShowCus
    },
    dialogManEvent() {
      this.isShowMan = !this.isShowMan
    },
    deleteCus(row) {
      this.handleText = '确定是否要删除该项？'
      this.rowData = row
      this.doHandleEvent()
    },
    doHandleEvent() {
      this.isShowDel = !this.isShowDel
    },
    deleStu() {
      this.$request.deleteCusService({id: this.rowData.id}).then(res => {
        if (res.data.code == 0) {
          this.doHandleEvent()
          this.Success(res.data.msg || '删除成功')
          this.getTableData()
        }
      })
    },
    visitEvent(row) {
      if(row.enterpriseWechatUrl) window.open(row.enterpriseWechatUrl)
    },
    chageProStatus(row) {
      // if(row.provinceFlag)
      this.$request.setCusServiceProvince({id: row.id}).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
        } else {
          row.provinceFlag = row.provinceFlag == 1 ? 0 : 1
        }
      }).catch(err => {
        row.provinceFlag = row.provinceFlag == 1 ? 0 : 1
      })
    },
    // 获取管理学校
    getProvinceToSchoolList() {
      this.$request.provinceToSchoolList({provinceId: this.formInline.provinceId}).then(res => {
        if (res.data.code == 0) {
          this.provinceSchoolData = res.data.data
        }
      })
    },
    // 获取管理成员
    getCustomerServiceObjList() {
      this.$request.customerServiceObjList().then(res => {
        if (res.data.code == 0) {
          this.customerServiceData = res.data.data
        }
      })
    },
    toolEvent(row, property) {
      if (property == 'enterpriseWechatUrl')
        this.visitEvent(row)
      else
        this.watchCus(row)
    },
  }
}
</script>

<style lang="less" scoped>
  .customerServiceConfig {
    height: 100%;
    .cus_area {
      box-sizing: border-box;
      padding: 30px;
    }
    /deep/ .el-scrollbar__view {
      height: 100%;
    }
    .man_area, .uni-area {
      box-sizing: border-box;
      padding: 30px;
    }
    .uni-area {
      p {
        font-size: 16px;
        margin-bottom: 20px;
      }
      p:nth-of-type(2) {
        color: #acaaaa;
      }
      .el-input {
        margin-bottom: 20px;
      }
      .btn_area {
        text-align: center;
      }
    }
    .sch_area {
      box-sizing: border-box;
      padding-bottom: 10px;
      /deep/.el-form-item {
        margin-bottom: 0;
      }
    }
    .blue-btn {
      color: #409eff;
      cursor: pointer;
    }
  }
</style>